import log from 'loglevel';

// Set the desired log level
log.setLevel('debug');

// Helper function to route logs to stdout or stderr
const writeToConsole = (level: string, ...args: any[]) => {
  const message = args.map(arg => (typeof arg === 'string' ? arg : JSON.stringify(arg))).join(' ');
  const prefix = `[${level.toUpperCase()}]`;

  if (level === 'error' || level === 'warn') {
    // Route warnings and errors to stderr
    console.error(`${prefix} ${message}`);
  } else {
    // Route debug and info to stdout
    console.log(`${prefix} ${message}`);
  }
};

// Override log.debug to log to both the browser console and stdout
log.debug = (...args: any[]) => {
  writeToConsole('debug', ...args);
};

// Override log.info, log.warn, log.error
log.info = (...args: any[]) => {
  writeToConsole('info', ...args);
};

log.warn = (...args: any[]) => {
  writeToConsole('warn', ...args);
};

log.error = (...args: any[]) => {
  writeToConsole('error', ...args);
};

export default log;
