import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ChatFloating from "./Components/ChatFloating/ChatFloating";

const port = process.env.REACT_APP_PORT || 4000;
console.log(`React app is running on port ${port}`);

function App() {
  return (
    <Router>
      {/* Define Routes */}
      <Routes>
        {/* Default Route (ChatFloating) */}
        <Route path="/" element={<ChatFloating />} />
      </Routes>
    </Router>
  );
}

export default App;
