
import axios from "axios";
import log from "../logging";

const API_URL = process.env.REACT_APP_API_URL || '';


export const fetchBotResponse = async (
  userMessage: string,
  previousMessages: any[] = [],
  previousConversation: any[] = [],
  setPreviousConversation: (conversation: any) => void
): Promise<string> => {
  try {
    const payload ={
      "sourceNumber":1,
      "metadata":{
         "eventType":"RAG_AI_Backend_Operation"
      },
      "payload":{
         "records":[
            {
               "eventVersion":"0.1",
               "eventSource":"RAG_AI_Service",
               "eventTime":new Date().toISOString(),
               "eventName":"RAG_AI_Retrieval",
               "eventUserId":"b3a68c97-3b62-4b18-9b5d-dcfa0a3f7c09",
               "eventSessionId":"5e12f11b-cbb9-439e-9fe9-3df1191fc1a6",
               "eventMessage":{
                  "info":{
                     "question":userMessage,               
                     "prev_conversation":previousConversation,
                     "metadata":{
                        "dataSize":"",
                        "retryCount":"0",
                        "activityType":"ragAiBackendOperation",
                        "trackingId":"ecc9704a-3cc1-4d32-82c0-4e3c674e545b"
                     }
                  }
               },
               "eventError":{
                  
               }
            }
         ]
      }
   };
    log.debug("API Request Payload:", JSON.stringify(payload, null, 2));

    const response = await axios.post(API_URL, payload);
    log.debug("API Response:", JSON.stringify(response.data, null, 2));

    if (
      response.data?.response &&
      Array.isArray(response.data.response) &&
      response.data.response[0]?.result &&
      Array.isArray(response.data.response[0].result)
    ) {
      const botResponse = response.data.response[0].result[0]?.conversation_response;
      
      if (response.data.response[0].prev_conversation) {
        setPreviousConversation(response.data.response[0].prev_conversation);
      }if (botResponse) {
        log.info("Bot response successfully extracted:", botResponse);
        return botResponse;
      }
    }
     
    const errorMessage = `Unexpected API response structure: ${JSON.stringify(response.data)}`;
    log.error(errorMessage);
    throw new Error(errorMessage);

  } catch (error: any) {
    log.error("Error occurred while fetching bot response:", {
      message: error.message,
      stack: error.stack || "No stack trace",
    });

    return "Error: Unable to fetch response.";
  }
};
